@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.text {
    padding: 0;
    margin: 0;
}

.default {
    font-size: 14px;
    @include fontWeight('regular');
}

.screenHeaderTitle {
    color: color('lightblack');
    @include fontWeight('bold');
    font-size: 16px;
}
