@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";

.container {
  display: flex;
  align-items: center;
  flex: 1;
}
.input {
  margin: 0 0.5rem 0 0.5rem;
  width: 5.5rem;
  font-size: 14px;
}
.label{
  font-size: 14px;
}