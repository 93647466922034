@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.formContent {
    width: 100%;
}

.formLine {
    display: flex;
    flex-direction: row;
}

.formItem {
    width: 50%;
    padding: 1rem;
}

.bold {
    @include fontWeight("bold");
}

.floatLeft {
    float: left;
}

.buttonsFooter {
    text-align: right;
    padding: 1rem;
}

.tableHeader {
    width: 7.14%;
    background-color: color(linkWater);
    border: 1px solid color(mercury);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding: 0.2rem 0;

    &:first-child {
        border-top-left-radius: 0.5rem;
    }

    &:last-child {
        border-top-right-radius: 0.5rem;
    }
}

.tableRow {
    width: 7.14%;
    border: 1px solid color(mercury);
    text-align: center;
    font-size: 14px;
    color: color("gray");
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
    position: relative;

    &.firstCol {
        background-color: color(catskillWhite);
        color: black;
        padding: 0;
    }

    // .input {
    //     &:hover:not(:focus) {
    //         &::-webkit-outer-spin-button,
    //         &::-webkit-inner-spin-button {
    //             -webkit-appearance: none;
    //             margin: 0;
    //         }
    
    //         -moz-appearance: textfield;
    //         padding-right: 1rem !important;
    //     }
    //     &:hover:focus {
    //         padding-right: 1rem !important;
    //     }
    // }

    // .edit {
    //     display: none;
    //     position: absolute;
    //     top: 50%;
    //     right: 0.5rem;
    //     transform: translate(0, -50%);
    // }

    // .label {
    //     display: block;
    // }

    // &:hover {
    //     padding-right: 1rem;
    //     .edit {
    //         display: block;
    //     }
    
    //     .label {
    //         display: none;
    //     } 
    // }

    // &:hover:focus-within {
    //     padding-right: 0;
    //     .edit {
    //         display: none;
    //     }
    
    //     .label {
    //         display: block;
    //     } 
    // }

}

.tableRemoveRow {
    width: 7.14%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.border {
    border-color: transparent;
}

.borderDisabled {
    border-color: color('whisper');
}

.inputGroup {
    padding: 0.5rem;
    flex: 1;
    text-align: center;
}