@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";

.riskContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    .riskBullets {
        display: flex;
        justify-content: flex-start;
        padding: 0.3rem 0.5rem;
        background: color(athensGray);
        border-radius: 20px;

        .riskBullet {
            margin: 0.2rem;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .riskDescription {
        flex: 1;
        margin-left: 1rem;
    }
}

.tooltip {
    white-space: nowrap; 
    max-width: 50vw; 
    overflow: hidden;
scrollbar-width: none; 
    text-overflow: ellipsis;
}