@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

$itemVerticalPadding: 1.2rem;
$itemHorizontalPadding: 1.5rem;

.fullWidth{
    width: 100%;
}

.tabs {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    border-bottom: 1px solid color('mercury');
    flex-wrap: wrap;
}

.tabs > li {
    padding: 0.5em 1em;
    margin: 0 1em;
    cursor: pointer;
}
.current {
    color: color('primary');
    font-weight: bold;
    position: relative;
    border-bottom: 2px solid color('primary');
}

.content {
   padding: 1em 1em;
}

.label{
    word-break: initial;
}