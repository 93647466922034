@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

$cellVerticalPadding: 6px;
$cellHorizontalPadding: 8px;

.bold {
    @include fontWeight("bold");
}

.formContent {
    flex: 1;
    height: 100%;
}

.columns {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.columnCell {
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    color: color('gray');
    @include fontWeight('bold');
    padding: $cellVerticalPadding $cellHorizontalPadding;
    cursor: pointer;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    // white-space: nowrap;
    // overflow: hidden;
scrollbar-width: none;
    // text-overflow: ellipsis;
    
}

.columnCellAction {
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    color: color('gray');
    @include fontWeight('bold');
    padding: $cellVerticalPadding $cellHorizontalPadding;
}

.buttonsFooter {
    text-align: right;
}

.rowCell {
    color: color('lightblack');
    font-size: 14px;
    padding: $cellVerticalPadding $cellHorizontalPadding;
    
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    // white-space: nowrap;
    // overflow: hidden;
scrollbar-width: none;
    // text-overflow: ellipsis;
}

.paddingSearch{
    padding: 0.55rem !important;
}

.paddingInputSearch{
    //padding: 0.68rem !important;
    height: 42.59px;
    font-size: 13px;

    padding-top: 9.6px !important;
    padding-bottom: 9.6px !important;
}

.toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .toolbarTitle {
        flex: 1;
    }

    .toolbarButton {
        margin-left: 10px;
        width: 2.5rem !important;
        height: 2.5rem !important;    
    }

    .toolbarButtonIcon{
        color: color('primary');
    }
}

.dateRangeCustom {
    display: inline-flex;
    justify-content: space-between;
    font-size: 13px;

    input{
        font-size: 13px;
    }
}