@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";
.container {
    display: flex;
    border: 1px solid color("mercury");
    height: 48px;
}

.leftNameContainer {
    float: left;
    display: flex;
    border-right: 1px solid #E8E8E8;
}

.leftName {
    width: 150px;
    background-color: color("athensGray");
    padding: 0.7rem;
    color: color("lightblack");
    @include fontWeight("bold");
    
    @include respond-below(lg) {
        width: 200px;
    }
    flex:1;
}

.percentageAchievementBox{
    width: 130px;
}

.percentageAchievementInput{
    border: none;
}

.boxValues{
    width: 300px;
    display: inline-block;
}

.boxValuesFixed{
    display: inline-block;
}

.areaValue{
    overflow: hidden;
scrollbar-width: none;
    white-space: nowrap;
}

.areaValueFixed{
    overflow: hidden;
scrollbar-width: none;
    white-space: nowrap;
    width: 100%;
}

.scrollPane {
    overflow-y: hidden;
    overflow-x: auto;
    
    &::-webkit-scrollbar {
        height: 0rem;
    }
}

.monthsContainer {
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: center;
    //height: 46px;
    border-right: 1px solid color('mercury');
    position: relative;
}


.monthItemChecked {
    flex: 1;
    color: color('gray');
    font-size: 10px;
    padding: 4px;
    cursor: pointer;
    //position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 46px;
}

.monthItem {
    flex: 1;
    color: color('gray');
    font-size: 10px;
    //padding: 4px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 46px;

    .monthBorderBottom {
        bottom: 0;
        position: absolute;
        left    : 0;
        top  : 90%;
        height  : 4px;
        border-bottom:4px solid;
    }

    .monthBorderBottom50 {
        width: 50%;
    }

    .monthBorderBottomRight {
        border-right: 1px solid color('gray');
        right: 0;
    }

    .monthBorderBottomLeft {
        border-left: 1px solid color('gray');
        left: 0;
    }
    
    &.checked {
        color: color('white');
        background-color: color('primary');
    }
    
    &.checkedDisabled {
        color: color('white');
        // background-color: color('gray');
        background-color: color('primary');
    }
    
}

.deselectAllButton {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: color('white');
    border: 1px solid color('primary');
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;

    .icon {
        color: color('primary');
        font-size: 8px;
        top: 3px;
        left: 3px;
        position: absolute;
    }
}

.popoverWidth {
    width: 25rem;
}

.formContent {
    width: 100%;
}

.bold {
    @include fontWeight("bold");
}

.buttonsFooter {
    text-align: right;
    padding: 1rem 0;
}

.datePopover {
    @include fontWeight("bold");
    text-align: right;
}

.align{
    align-self: center;
}

.msgWithoutDSIResp{
    display: flex;
    justify-content: center;
}

.fullWidth{
    height: 100%;
    width: 100%;
}