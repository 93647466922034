@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.content{
    width: 100%;
    padding: 0 15px 5px 0;
}

.buttonText{
    float: left;
}

.buttonIcon{
    float: right;
}

.iconSize {
    font-size: 14px;
}

.buttonParent {
    background-color: color("white");
    border: 1px solid color("mercury");
    border-radius: 2px;
    font-family: 'Roboto';
    @include fontWeight('bold');
    font-size: 16px;
    color: color("lightblack");
    text-transform: uppercase;
    opacity: 1;
    cursor: pointer;
    outline: 0;
    padding: 1rem 1rem;
    width: 100%;
    text-align: left;
    height: 60px;
}

.buttonChild {
    background-color: color("white");
    border: 1px solid color("mercury");
    border-radius: 2px;
    font-family: 'Roboto';
    @include fontWeight('bold');
    font-size: 12px;
    color: color("lightblack");
    text-transform: uppercase;
    opacity: 1;
    cursor: pointer;
    outline: 0;
    padding: 0.5rem;
    width: 100%;
    text-align: left;
}

.buttonDisable {
    background-color: color("linkWater");
    border: 1px solid color("mercury");
    border-radius: 2px;
    font-family: 'Roboto';
    @include fontWeight('bold');
    font-size: 16px;
    color: color("primary");
    text-transform: uppercase;
    opacity: 1;
    cursor: initial;
    outline: 0;
    padding: 1rem 1rem;
    width: 100%;
    text-align: left;
}

.active{
    background-color: color("linkWater");
    color: color("primary");
}

.buttonLabel {
    background-color: color("primary");
    border: 0;
    color: color('white');
    border-radius: 25px;
    outline: 0;
    display: inline-block;
    margin-left: 1rem;
    padding: 0.3rem 1rem;
    font-size: 10px;
    @include fontWeight('normal');
}