@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.formContent {
    flex: 1;
}

.formLine {
    display: flex;
    flex-direction: row;
}

.formItem {
    width: 100%;
}

.bold {
    @include fontWeight("bold");
}

.floatLeft {
    float: left;
}

.buttonsFooter {
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.colabName{
    margin-top: 10px;
}