@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.boxContent {
    padding: 0;
}

.boxBadge {
    margin-right: 10px;
    font-style: normal;
}

.bold {
    @include fontWeight("bold");
}

.title {
    width: 100%;
    text-align: left;
    font-size: 16px;
    color: color("primary");
    @include fontWeight("bold");
    border-bottom: 1px solid color("mercury");
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.line {
    background: #fff;
    padding-top: 0px;
    padding-right: 10px;
}

.button {
    font-size: 12px;
    color: color("primary");
}

.addPhaseButton {
    background-color: color("white");
    box-shadow: 0px 3px 6px color("botticelliapprox");
    border: 1px solid color("mercury");
    border-radius: 25px;
    cursor: pointer;
    outline: 0;
    display: inline-block;
    margin-left: 0;

    &:hover {
        background-color: darken(color("white"), 5%);
    }

    .addPhaseButtonIcon {
        color: color("primary");
    }
    .addPhaseButtonText {
        color: color("primary");
        font-size: 12px;
        padding-left: 5px;
    }
}

.removePhaseButton {
    color: color("primary");
    font-size: 12px;
}

.phaseLine {
    align-content: center;
    display: flex;
}

.withoutElements {
    color: color("primary");
    background: color("lightGrayishBlue");
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    padding: 10px 10px 10px 10px;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
scrollbar-width: none;
    text-align: center;
}

.riskContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;

    .riskBullets {
        display: flex;
        justify-content: flex-start;
        padding: 0.3rem 0.5rem;
        background: color(athensGray);
        border-radius: 20px;

        .riskBullet {
            margin: 0.2rem;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .riskDescription {
        flex: 1;
        margin-left: 1rem;
    }
}
