@import "../../../../../styles/utils/functions.scss";
@import "../../../../../styles/utils/mixins.scss";
@import "../../../../../styles/utils/responsive.scss";
.container {
    display: flex;
    border-top: 1px solid color("mercury");
    height: 100%;
}

.leftNameContainer {
    float: left;
    display: flex;
    border-right: 1px solid #E8E8E8;
}

.leftName {
    width: 150px;
    background-color: color("athensGray");
    padding: 0.7rem;
    color: color("lightblack");
    @include fontWeight("bold");
    
    @include respond-below(lg) {
        width: 200px;
    }
    flex:1;
}

.percentageAchievementBox{
    width: 130px;
}

.percentageAchievementInput{
    border: none;
}

.boxValues{
    width: 80px;
    display: inline-block;
    height: 100%;
    min-height: 48px;
}

.boxValuesFixed{
    display: inline-block;
}

.areaValue{
    overflow: hidden;
scrollbar-width: none;
    white-space: nowrap;
    height: 100%;
    width: 100%;
}

.areaValueFixed{
    overflow: hidden;
scrollbar-width: none;
    white-space: nowrap;
    width: 100%;

    height: 48px;
}

.scrollPane {
    overflow-y: hidden;
    overflow-x: auto;
    
    &::-webkit-scrollbar {
        height: 0rem;
    }
}

.monthsContainer {
    //display: flex;
    //flex-direction: row;
    // align-items: center;
    justify-content: center;
    // height: 46px;
    border-right: 1px solid color('mercury');
    position: relative;
    height: 100%;
}

.monthItem {
    flex: 1;
    color: color('lightblack');
    font-size: 14px;
    padding: 4px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    justify-content: flex-end;

    .monthBorderBottom {
        bottom: 0;
        position: absolute;
        left    : 0;
        top  : 90%;
        height  : 4px;
        border-bottom:4px solid;
    }

    .monthBorderBottom50 {
        width: 50%;
    }

    .monthBorderBottomRight {
        border-right: 1px solid color('gray');
        right: 0;
    }

    .monthBorderBottomLeft {
        border-left: 1px solid color('gray');
        left: 0;
    }
    
    &.selected {
        background-color: color('pattensblue');
    }

    &.checked {
        position: absolute;
        width: 105%;
        color: color('white');
        background-color: color('primary');
        height: 50%;
        top: 25%; //top 25% because he has 50 % of heigth, then more 25% of top, he will assume 25% on bottom

        &.borderRadiusLeft{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &.borderRadiusRight{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    
}

.deselectAllButton {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: color('white');
    border: 1px solid color('primary');
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;

    .icon {
        color: color('primary');
        font-size: 8px;
        top: 3px;
        left: 3px;
        position: absolute;
    }
}

.todayCircle{
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #83FFFF;
    //top: 5.7rem;
    border-radius: 5px;
}

.todayLine{
    position: absolute;
    width: 2.5px;
    height: 100%;
    background-color: #83FFFF;
}

.continue{
    margin-right: 6%;
}

.alignContinueLeft{
    justify-content: flex-start;
}

.alignContinueRight{
    justify-content: flex-end;
}