@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";

.areaDetails{
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.areaValue{
    text-align: center;
    overflow: hidden;
scrollbar-width: none;
    white-space: nowrap;
}

.areaValueFixed{
    text-align: center;
    overflow: hidden;
scrollbar-width: none;
    white-space: nowrap;
    width: 100%;
}

.areaValueHeader{
    background: color("lightGrayishBlue");
    text-align: center;
    overflow: hidden;
scrollbar-width: none;
    white-space: nowrap;
}

.areaValueHeaderFixed{
    background: color("lightGrayishBlue");
    text-align: center;
    overflow: hidden;
scrollbar-width: none;
    white-space: nowrap;
    width: 100%;
}

.areaValueFooter{
    background: color("lightGrayishBlue");
    text-align: center;
    overflow: hidden;
scrollbar-width: none;
    overflow-x: auto;
    white-space: nowrap;
    @include scrollbars();
}

.areaValueFooterFixed{
    background: color("lightGrayishBlue");
    text-align: center;
    overflow: hidden;
scrollbar-width: none;
    overflow-x: auto;
    white-space: nowrap;
    @include scrollbars();
    width: 100%;
    display: flex;
}

.headerOutside {
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
scrollbar-width: none;
}

.flex{
    display: flex;
}

.header {
    background: color("lightGrayishBlue");
    border-radius: 20px 0px 0px 0px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
scrollbar-width: none;
    padding: 5px 10px 5px 10px
}

.footer {
    background: color("lightGrayishBlue");
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
scrollbar-width: none;
}

.boxDetails{
    width: 300px;
    float: left;
}

.boxValues{
    width: 200px;
    display: inline-block;
}

.boxValuesFixed{
    display: inline-block;
    padding: 5px 0px 5px 0px
}

.boxValuesTotal{
    text-align: right;
    @include fontWeight("bold");  
    width: 200px;
    display: inline-block;
    padding: 5px 40px 5px 0px;
}

.boxValuesTotalFixed{
    text-align: right;
    @include fontWeight("bold");  
    display: inline-block;
    padding: 5px 40px 5px 0px;
}

.shadowBall {
    position: absolute;
    top: -20px;
    left: -20px;
    background-color: rgba(color('primary'), 0.05);
    width: 75px;
    height: 80px;
    border-radius: 50%;
}

.labelContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.labelHeaderOutside {  
    font-size: 15px;
    color: color("primary");
    letter-spacing: 0px;
}

.labelHeader {
    @include fontWeight("bold");  
    font-size: 15px;
    color: color("primary");
    letter-spacing: 0px;
    height: 100%;
    display: inline-grid;
    align-items: center;
}

.labelHeaderTitle {
    @include fontWeight("bold");  
    font-size: 15px;
    color: color("primary");
    letter-spacing: 0px;
    height: 100%;
    display: inline-grid;
    align-items: center;
    margin: 0 0 0 20px;
}

.buttonPopover {
    padding: 0 10px 0px 10px;
    float: right;
}

.labelFooterNormal {
    @include fontWeight("bold");  
    font-size: 15px;
    color: color("primary");
    letter-spacing: 0px;
    display: inline-flex;
    width: 100%;
}

.labelFooterSmall {
    font-size: 12px;
    text-align: right;
    width: 80%;
}

.iconHeader {
    @include fontWeight("bold");
    text-align: left;
    margin: 5px 0 5px 0;
    font-size: 18px;
    color: color('primary');
}

.iconFooter {
    @include fontWeight("bold");
    text-align: left;
    margin: 5px;
    font-size: 18px;
    color: color('primary');
}

.content {
    background-color: color("white");
    border-top: 0;
}

.formContent {
    width: 100%;
}

.bold {
    @include fontWeight("bold");
}

.buttonsFooter {
    text-align: right;
    padding: 1rem
}

.labelFooterTotal{
    width: 80%;
    text-align: right;
}

.scrollPane {
    overflow-y: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 0rem;
    }
}

.footerContainer {
    height: 7px;
}
