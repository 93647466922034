@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.left {
    float: left;
    width: 25%;
}

.right {
    float: right;
    width: 75%;
}

.toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .toolbarTitle {
        flex: 1;
    }

    .toolbarButton {
        margin-left: 10px;
    }

    .toolbarButtonIcon{
        color: color('primary');
    }
}

.boxContentMinHeight{
    min-height: 627px;
}
