@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    display: flex;
    height: 100%;
}

.containerBorder{
    border-bottom: 1px solid color("mercury");
}

.column{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px 8px;
    font-size: 14px;
}

.columnValues{
    justify-content: flex-start
}

.bold{
    @include fontWeight('bold');
}

.columnHide{
    @media (max-width:990px) {
        display: none;
    }
}