@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.header {
    background: color("white");
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    border-right: 1px solid color("mercury");
}

.content {
    background-color: color("white");
    border-top: 1px solid color("mercury");
    border-right: 1px solid color("mercury");
}

.flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
}

.flexRowColumns{
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    color: #BEBEBE;
    font-weight: 700;
    padding: 6px 8px;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid color('mercury');
    font-size: 14px;
    color: color('lightblack');
    height: 100%;
}
.noResultsText {
    text-align: center;
    color: color('gray');
    padding: 1rem;
    justify-content: center;
}

