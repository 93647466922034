@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";


.bold {
    @include fontWeight("bold");
}



.withoutElements{
    color: color('primary');
    background: color("lightGrayishBlue");
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    padding: 10px 10px 10px 10px;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
scrollbar-width: none;
    text-align: center;
}


.addButton {
    background-color: color('white');
    box-shadow: 0px 3px 6px color('botticelliapprox');
    border: 1px solid color('mercury');
    border-radius: 25px;
    cursor: pointer;
    outline: 0;
    display: inline-block;
    margin-left:0;

    &:hover {
        background-color: darken(color('white'), 5%);
    }

    .addButtonIcon {
        color: color('primary');
    }
    .addButtonText {
        color: color('primary');
        font-size: 12px;
        padding-left: 5px;
    }
}

.removeButton {
    color: color('primary');
    font-size: 12px;    
}

.align{
    align-self: center;
    text-align: center;
}

.row{
    display: flex; 
    flex-wrap: 'wrap';
    flex-direction: 'row';
    margin-bottom: 15px; 
}

.col{
    padding-left: 8px;
    padding-right: 8px
}