@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.areaDetails {
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 150px;
}

.areaValue {
    text-align: center;
    overflow: hidden;
    scrollbar-width: none;
    white-space: nowrap;
    width: 100%;
}

.areaValueFixed {
    text-align: center;
    overflow: hidden;
    scrollbar-width: none;
    white-space: nowrap;
    width: 100%;
}

.areaValueHeader {
    background: color("white");
    text-align: center;
    overflow: hidden;
    scrollbar-width: none;
    white-space: nowrap;
    height: 100%;
    position: relative;
    width: 100%;
}

.areaValueHeaderFixed {
    background: color("white");
    text-align: center;
    overflow: hidden;
    scrollbar-width: none;
    white-space: nowrap;
    width: 100%;
}

.areaValueFooter {
    background: color("lightGrayishBlue");
    text-align: center;
    overflow: hidden;
    scrollbar-width: none;
    overflow-x: auto;
    white-space: nowrap;
    flex: 1;
    @include scrollbars();
}

.areaValueFooterFixed {
    background: color("lightGrayishBlue");
    text-align: center;
    overflow: hidden;
    scrollbar-width: none;
    overflow-x: auto;
    white-space: nowrap;
    @include scrollbars();
    width: 100%;
    display: flex;
}

.headerOutside {
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
    scrollbar-width: none;
}

.flex {
    display: flex;
}

.header {
    background: color("white");
    border-radius: 20px 0px 0px 0px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
    scrollbar-width: none;
    padding: 5px 10px 5px 10px;
}

.footer {
    background: color("lightGrayishBlue");
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
    scrollbar-width: none;
}

.boxDetails {
    width: 150px;
    float: left;

    @include respond-below(lg) {
        width: 200px;
    }
}

.boxValues {
    display: inline-block;
    padding: 5px 0px 5px 0px;
}

.boxValuesHeader {
    border-right: solid 2px color("mercury");
}

s.shadowBall {
    position: absolute;
    top: -20px;
    left: -20px;
    background-color: rgba(color("primary"), 0.05);
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.labelContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.labelHeaderOutside {
    font-size: 15px;
    color: color("primary");
    letter-spacing: 0px;
}

.labelHeader {
    font-size: 0.95rem;
    color: color("lightblack");
    letter-spacing: 0px;
    height: 100%;
    display: inline-grid;
    align-items: center;
}

.labelHeaderTitle {
    @include fontWeight("bold");
    font-size: 15px;
    color: color("primary");
    letter-spacing: 0px;
    height: 100%;
    display: inline-grid;
    align-items: center;
    margin: 0 0 0 20px;
}

.buttonPopover {
    padding: 0 10px 0px 10px;
    float: right;
}

.labelFooterNormal {
    @include fontWeight("bold");
    font-size: 15px;
    color: color("primary");
    letter-spacing: 0px;
    display: inline-flex;
    width: 100%;
}

.labelFooterSmall {
    font-size: 12px;
    text-align: right;
    width: 80%;
}

.iconHeader {
    @include fontWeight("bold");
    text-align: left;
    margin: 5px 0 5px 0;
    font-size: 18px;
    color: color("primary");
}

.iconFooter {
    @include fontWeight("bold");
    text-align: left;
    margin: 5px;
    font-size: 18px;
    color: color("primary");
}

.content {
    background-color: color("white");
    border-top: 0;
    position: relative;
    border-bottom: 1px solid #e8e8e8;
}

.formContent {
    width: 100%;
}

.bold {
    @include fontWeight("bold");
}

.buttonsFooter {
    text-align: right;
    padding: 1rem;
}

.labelFooterTotal {
    width: 80%;
    text-align: right;
}

.scrollPane {
    overflow-y: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 0rem;
    }
}

.footerContainer {
    height: 7px;
}

.noLinesText {
    font-size: 14px;
    border: 1px solid color("mercury");
    padding: 10px;
}

.todayCircle {
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: #83ffff;
    //top: 5.7rem;
    border-radius: 5px;
    z-index: 1;
    top: -4px;
}

.headerTextContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.headerText {
    text-align: center;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: color("gray");
    @include fontWeight("bold");
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid color("mercury");
    border-right: 1px solid color("mercury");
    font-size: 14px;
    color: color("lightblack");
    height: 100%;
}
.noResultsText {
    text-align: center;
    color: color("gray");
    padding: 1rem;
    justify-content: center;
}
