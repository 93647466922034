@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/theme/variables.scss";

$itemPaddingHorizontal: 20px;
$itemPaddingVertical: 20px;

.container {
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid color('mercury');
    z-index: 600;
    background-color: color('athensGray');
    position: relative;
}

.innerContainer {
    @include containerWrapper;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.items {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

.item {
    color: color('lightblack');
    font-size: 14px;
    padding: 0 $itemPaddingHorizontal;
    cursor: pointer;
    @include fontWeight('bold');

    span {
        padding: $itemPaddingVertical 0;
        display: block;
        border-bottom: 2px solid transparent;
        transition: 0.2s;
    }

    &:first-child {
        padding-left: 0;
    }

    &.active,
    &:hover {
        span {
            border-bottom: 2px solid color('primary');
        }
    }
}

.closeButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0;
    background-color: transparent;
    outline: 0;
    cursor: pointer;

    .closeButtonText {
        color: color('primary');
        font-size: 14px;
        padding-right: 5px;
    }

    .closeButtonIcon {
        color: color('primary');
    }
}

.sticky {
    position: sticky;
    top: 0;
}
.name{
    display: flex;
    max-width: 15rem;
    font-size: 13px;
    @include fontWeight('normal');
    margin-bottom: 0.5rem;
}