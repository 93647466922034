@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.formContent {
    width: 100%;
}

.formLine {
    flex-direction: row;
}

.formItem {
    width: 50%;
    padding: 1rem
}

.floatLeft {
    float: left;
}

.bold {
    @include fontWeight("bold");
}

.buttonsFooter {
    text-align: right;
    padding: 1rem;
    bottom: 0;
    width: 100%;
}

.content{
    width: 100%;
    margin-bottom: 20px;
}

.contentRole{
    border-left: 1px solid color('mercury');
    border-right: 1px solid color('mercury');
    border-bottom: 1px solid color('mercury');
}

.buttonParent {
    background-color: color("white");
    border: 1px solid color("mercury");
    border-radius: 2px;
    font-family: 'Roboto';
    @include fontWeight('bold');
    font-size: 16px;
    color: color("lightblack");
    text-transform: uppercase;
    opacity: 1;
    cursor: pointer;
    outline: 0;
    padding: 1rem 1rem;
    width: 100%;
    text-align: left;
    height: 60px;
}

.buttonDisable {
    background-color: color("linkWater");
    border: 1px solid color("mercury");
    border-radius: 2px;
    font-family: 'Roboto';
    @include fontWeight('bold');
    font-size: 16px;
    color: color("primary");
    text-transform: uppercase;
    opacity: 1;
    cursor: initial;
    outline: 0;
    padding: 1rem 1rem;
    width: 100%;
    text-align: left;
}

.active{
    background-color: color("linkWater");
    color: color("primary");
}

.buttonText{
    float: left;
}

.buttonIcon{
    float: right;
}

.iconSize {
    font-size: 14px;
}

.linePolicy {
    display: flex;
    padding: 10px 20px 10px 20px;
    width: 100%;
}

.policyTitle {
    width: 50%;
}

.policyOption {
    width: 25%;
}

.withoutElements{
    color: color('primary');
    background: color("lightGrayishBlue");
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    padding: 10px 10px 10px 10px;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
scrollbar-width: none;
    text-align: center;
}