@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";


.paddingSearch{
    padding: 0.55rem !important;
}

.inputGroup {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    background-color: color('white');
}

.input{
    height: 100%;
    font-size: 13px;
    padding-top: 9.6px;
    padding-bottom: 9.6px;
}