@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.sideBar {
    //height: 100% !important;
    display: flex;
    flex-direction: column;
    transition: 0.8s ease;
    position: fixed;
    right: 0;
    background: color('white') 0% 0% no-repeat padding-box;
    border: 1px solid color('whisper');
    min-width: 300px;
}

.toggleMenu {
    height: 40px;
    width: 53px;
    position: absolute;
    z-index: 1;
    border: 1px solid color('whisper');
    background: color('white') 0% 0% no-repeat padding-box;
    border-radius: 20px 0px 0px 20px;
    opacity: 1;
    border-right: 0;
    box-shadow: -2px 2px 6px #00000029;
    cursor: pointer;
    
    &:hover {
        background-color: darken(color('white'), 5%);
    }
}

.toggleMenuIcon{
    color: color('primary');
}

.content{
    padding: 1rem;
    padding-top: 0.5rem;
    height: 100%;
}