@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.bold {
    @include fontWeight("bold");
}

.formContent {
    width: 100%;  
}

.buttonsFooter {
    text-align: right;
}

.cleanButton{
    padding: 0.6rem 1.35rem !important;
    font-size: 0.95rem !important;
    font-weight: 300;
    border: 1px solid color("primary") !important;
}

.filterButton{
    padding: 0.6rem 1.35rem !important;
    font-size: 0.95rem !important;
    font-weight: 300;
}