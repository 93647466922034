@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.textAlignLeft{
    text-align: left;
}

.textAlignCenter{ 
    text-align: center;
}

.textAlignRight{
    text-align: right;
}

.toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    // margin-bottom: 10px;

    .toolbarTitle {
        flex: 1;
    }
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.5rem;

    .paginationInfo {
        flex: 1;  
        font-size: 14px;
        color: color('gray');
    }
}

.filtersPopoverContainer {
    margin-top: 1rem;
}

.counter {
    border-radius: 50%;
    background-color: color('primary');
    color: color('white');
    font-size: 10px;
    display: inline-block;
    flex-direction: row;
    width: 15px;
    height: 15px;
    position: absolute;
    mix-blend-mode:darken;
    margin-left: 5px;  
  }

  .counterNumber{
      padding-top: 2px;
  }

.noMarginPadding {
    word-break: break-word !important;
    white-space: initial !important;
    margin: 0 !important;
    padding: 0 !important;
}

.projectBox {
    margin-top: 1.5rem;
}

.btnActions{
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.numberOfPages{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;

    .toolbarButton {
        margin-left: 0.5rem;      
    }

    .toolbarButtonIcon{
        color: color('primary');
    }
} 