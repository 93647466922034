
@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.formContent {
    width: 100%;
    @include containerWrapper;
}

.formLine {
    display: flex;
    flex-direction: row;
}

.formItem {
    width: 50%;
    // padding: 1rem
}

.button {
    margin-left: 1rem;
}

.bold {
    @include fontWeight("bold");
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.buttonsFooter {
    width: 100%;
    text-align: right;
    // padding: 1rem
}

.sectionContent {
    text-align: left;
    padding: 1rem;
    color: color('frenchGray');
    text-transform: uppercase;
}