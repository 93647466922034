@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";


.header {
    background: color("lightGrayishBlue");
    padding: 8px 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.labelContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.icon {
    font-size: 14px;
    color: color('primary');
}

.title {
    @include fontWeight("bold");
    font-size: 16px;
    color: color("primary");
    margin-left: 10px;
}

.content {
    padding: 1rem;
}