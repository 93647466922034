@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.item {
    height: 100%;
    width: 100%;
    user-select: none;
    background: color('white');
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    @include fontWeight('bold');
}
