@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    height: 100%;
    min-height: 34px;
}

.border{
    border-bottom: 1px solid color("mercury");
}

.column{
    display: flex;
    align-items: center;
    padding: 6px 8px;
    font-size: 14px;
}

.columnValues{
    justify-content: flex-start;
}

.columnInfo{
    margin-left: 0.5rem;
    align-items: center;
    justify-content: flex-end;

    @media (max-width:1200px) {
        display: none;
    }
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.flexRow {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    @media (max-width:990px) {
        flex-direction: column;
    }
}

.iconExpand {
    float: right;
    color: color('black');
    margin-top: 2px;
    cursor: pointer;
}

.bold{
    @include fontWeight('bold');
}

.labelInfo{
    color: color("gray");
    font-size: 12px;
    font-style: italic;
}

.labelTime{
    min-width: 92px;
}

.buttons{
    display: flex;
    flex-direction: row;
}