@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;

  a {
    outline : 0;
    border: 0;
  }
}

.pages{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page {
  width: 30px;
  height: 30px;
  background-color: color('white');
  border-radius: 50%;
  cursor:pointer;  
  color: color('lightblack');
  font-size: 16px;
  text-align:center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;

  &:last-child {
    margin-right: 0;
  }
}

.currentPage{
  background: color('primary');
  color: color('white');
  cursor: default;
}

.direction{
  cursor:pointer;  
  font-size:12px;
  color: color('primary');
  padding: 0 10px;
}

.directionDisabled {
  cursor: default;
  color: color('gray');
}

.paginationInfo {
  font-size: 14px;
  color: color('gray');
}

.buttonCustomization{
  width: 100%;
}