@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.header {
    background: color("lightGrayishBlue");
    border-radius: 20px 20px 0px 0px;
    opacity: 1;
    padding: 10px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
scrollbar-width: none;
    cursor: pointer;
}

.shadowBall {
    position: absolute;
    top: -20px;
    left: -20px;
    background-color: rgba(color('primary'), 0.05);
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.labelContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.textRight{
    text-align: right;
}

.labelHeader {
    @include fontWeight("bold");  
    font-size: 19px;
    color: color("primary");
    text-align: left;
    letter-spacing: 0px;
    margin: 5px;
    cursor: pointer;
}

.labelClose {
    @include fontWeight("bold");
    font-size: 14px;
    color: color("primary");
    text-align: right;
    letter-spacing: 0px;
    margin: 5px;
    float: right;
    cursor: pointer;
}

.iconHeader {
    @include fontWeight("bold");
    text-align: left;
    margin: 5px;
    font-size: 18px;
    color: color('primary');
}

.content {
    background-color: color("white");
    border: 1px solid color("mercury");
    border-top: 0;
    border-radius: 2px;
    padding: 1rem;
    padding-top: 1.5rem;
}