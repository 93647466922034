@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.button {
    border: 0;
    color: color('white');
    border-radius: 25px;
    cursor: pointer;
    outline: 0;
    display: inline-block;
    margin-left: 1rem;

    &.sizeNormal {
        padding: 0.7rem 1.8rem;
        font-size: 16px;
    }

    &.sizeSmall {
        padding: 0.5rem 1rem;
        font-size: 14px;
    }

    &.sizeExtraSmall {
        padding: 0.5rem 1rem;
        font-size: 10px;
    }
}

.buttonIcon {
    border: 0;
    color: color('white');
    border-radius: 50%;
    font-size: 13px;
    cursor: pointer;
    outline: 0;
    display: inline-block;
    
    &.sizeNormal {
        padding: 0.1rem;
        width: 43px;
        height: 43px;
        font-size: 16px;
    }

    &.sizeSmall {
        padding: 0.1rem;
        width: 30px;
        height: 30px;
        font-size: 13px;
    }

    &.sizeExtraSmall {
        padding: 0.1rem;
        width: 20px;
        height: 20px;
        font-size: 10px;
    }
}

.primary {
    background-color: color('primary');
    border: 1px solid color('primary');
    box-shadow: 0px 3px 6px #D0DDE6;

    &:hover {
        background-color: darken(color('primary'), 5%);
    }
}

.danger {
    background-color: color('danger');
    border: 1px solid color('danger');
    box-shadow: 0px 3px 6px #D0DDE6;

    &:hover {
        background-color: darken(color('danger'), 5%);
    }
}

.success {
    background-color: color('success');
    border: 1px solid color('success');
    box-shadow: 0px 3px 6px #D0DDE6;

    &:hover {
        background-color: darken(color('success'), 5%);
    }
}

.secondary {
    background-color: color('white');
    color: color('primary');
    box-shadow: 0px 3px 6px color('botticelliapprox');
    border: 1px solid color('mercury');

    &:hover {
        background-color: darken(color('white'), 5%);
    }
}

.popover {
    background-color: color('white');
    color: color('skyBlue');
    box-shadow: 0px 3px 6px color('botticelliapprox');
    border: 1px solid color('skyBlue');

    &:hover {
        background-color: darken(color('white'), 5%);
    }
}

.secondaryDanger {
    background-color: color('white');
    color: color('danger');
    box-shadow: 0px 3px 6px color('danger');

    &:hover {
        background-color: darken(color('white'),  5%);
    }
}

.gray {
    background-color: color('gray');
    color: color('white');
    box-shadow: 0px 3px 6px color('gray');
    &:hover {
        background-color: darken(color('gray'),  5%);
        
    }
}