@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.summernote{
    display: block;
    @include fontWeight('light');
    width: 100%; 
    font-family: 'Roboto', sans-serif;
    border: 1px solid transparent;
   
    &:focus {
        border: 1px solid color('primary');
    }    
}

.disabled{
    display: block;
    @include fontWeight('light');
    font-size: 16px;
    width: 100%; 
    font-family: 'Roboto', sans-serif;        
    background-color: color('whisper');
    opacity: 1;
}

    