@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.boxContent {
    padding: 0;
}

.bold {
    @include fontWeight("bold");
}

.subBoxHeader {
    background-color: color('linkWater');
}

.subBoxHeaderText {
    color: color('lightblack');
}
