@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";

.iconOrder {
    float: right;
    color: color("primary");
}

.rowHover:hover {
    background-color: color("solitude");
}

.rowClickable {
    cursor: pointer;
}

.textAlignLeft {
    text-align: left;
}

.textAlignCenter {
    text-align: center;
}

.textAlignRight {
    text-align: right;
}

.clearFilters {
    min-height: 41px;
    min-width: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

//Table
//.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
//    position: initial !important;
//}
.up,
.down {
    display: none;
}

.-sort-asc {
    .up {
        display: block !important;
    }
}
.-sort-desc {
    .down {
        display: block !important;
    }
}

.dateRangeCustom {
    display: inline-flex;
    justify-content: space-between;
}

.noData {
    height: 50px;
    width: 100%;
    text-align: center;
    background-color: white;
    padding: 1rem;
    color: color("gray");
}

.totalFilters {
    color: color("primary");
    margin-top: 3px;
}


.headerName{
    font-size: 12px;
    @include  fontWeight('bold');
}

.iconFilter{
    margin-right: 5px;
}




.riskContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;

    .riskBullets {
        display: flex;
        justify-content: flex-start;
        padding: 0.3rem 0.5rem;
        background: color(athensGray);
        border-radius: 20px;

        .riskBullet {
            margin: 0.2rem;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .riskDescription {
        flex: 1;
        margin-left: 1rem;
    }
}