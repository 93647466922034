@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";

.container {
    border: 1px solid color('mercury');
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
scrollbar-width: none;
}

.headerContainer {
    background-color: color('lightGrayishBlue');
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px;
}

.progressBarContainer {
    width: 100%;
    height: 3px;

    .progressBar {
        background-color: rgba(color("cornflowerBlue"), 0.5);
        width: 0%;
        height: 100%;
    }
}

.titleContainer {
    flex: 1 1 170px;
    display: flex;
    flex-direction: column;

    .title {
        font-size: 16px;
        @include fontWeight('bold');
        color: color('primary');
    }

    .subtitle {
        font-size: 12px;
        color: color('primary');

        &.subtitleDanger {
            color: color('danger');
        }
    }
}

.titleInputsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bodyContainer {
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.poleItem {
    flex: 1 1 170px;
}

.noPolesText {
    font-size: 14px;
}
