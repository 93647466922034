@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";

.flex{
     display: flex;
     border-bottom: 1px solid color("mercury");
}

.break{
    flex-basis: 100%;
  height: 0;
}

.bordercontentLine{
    border: 1px solid color("mercury");
}

.contentLine{
    border-top: 3px solid rgba(color("cornflowerBlue"), 0.5);
    padding: 1rem;
}

.boxDetails{
    width: 300px;
    float: left;
    background-color: color("athensGray");
    padding: 0.7rem 0 0.7rem;
    color: color("lightblack");
    @include fontWeight("bold");  
    cursor: pointer;
}

.boxValues{
    width: 200px;
    display: inline-block;
}

.boxValuesFixed{
    display: inline-block;
}

.areaDetails{
    float: left;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.areaValue{
    overflow: hidden;
scrollbar-width: none;
    white-space: nowrap;
    width: 100%;
}

.areaValueFixed{
    overflow: hidden;
scrollbar-width: none;
    white-space: nowrap;
    width: 100%;
}

.itemDetails{
    float: left;
    height: 100%;
    display: inline-flex;
    align-items: center;
    padding: 0 0.5rem 0 0.5rem;
}

.divSVG{
    min-width: 22px;
    text-align: center;
}

.textFormat {
    color: color('primary');
    font-size: 12px;
    margin-bottom: 1rem;
    display: block;

    &.textDanger {
        color: color('danger');
    }
}

.lineEstablishment {
    margin-bottom: 1rem;
}

.scrollPane {
    overflow-y: hidden;
    overflow-x: auto;
    
    &::-webkit-scrollbar {
        height: 0rem;
    }
}
