@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";

$shadowBallSize: 95px;
$paddingLeft: 50px;

.container {
    background: color('white');
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    overflow: hidden;
scrollbar-width: none;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
scrollbar-width: none;
    padding-left: $paddingLeft;

    .right {
        flex: 1;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.3rem;
        line-height: normal;
    }

    .shadowBall {
        position: absolute;
        top: -20px;
        left: -20px;
        background-color: color('tropicalBlue');
        width: $shadowBallSize;
        height: $shadowBallSize;
        border-radius: 50%;
    }

    .icon {
        position: absolute;
        top: 10px;
        left: 10px;

        img,
        svg {
            width: 45px;
            height: auto;
            margin-top: 5px;
        }
    }

    .title {
        text-align: right;
        font-size: 20px;
        letter-spacing: 0px;
        font-weight: bold;
        color: color('primary');
    }

    .subtitle {
        text-align: right;
        font-size: 18px;
        letter-spacing: 0px;
        color: color('primary');
        font-weight: bold;
    }

    .price {
        text-align: right;
        font-size: 1.8rem;
        letter-spacing: 0px;
        color: color('primary');
        font-weight: bold;
        position: relative;
    }
}

.bodyContainer {
    padding: 1rem;
    padding-top: 0;
}

.standout {
    background-color: color('lightGrayishBlue');
}

.editButtonContainer {
    cursor: pointer;
    position: relative;

    .editIcon {
        display: none;
        position: absolute;
        top: 10px;
        background-color: color('white');
        color: color('skyBlue');
        box-shadow: 0px 3px 6px color('botticelliapprox');
        border: 1px solid color('skyBlue');
        border-radius: 50%;
        padding: 0.1rem;
        width: 18px;
        height: 18px;
        font-size: 10px;

        &:hover {
            background-color: darken(color('white'), 5%);
        }
    }

    &:hover > .editIcon {
        display: inline-block;
    }
}

.popoverContentContainer {
    width: 200px;
    padding: 14px;
}

.popoverContainer {
    input {
        width: 100%;
    }
}

.bold {
    font-weight: bold;
}

.popoverInput {
    flex-wrap: nowrap !important;
}
