// @import "../../../styles/utils/functions.scss";
// @import "../../../styles/utils/mixins.scss";
// @import "../../../styles/theme/variables.scss";

@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/variables.scss";

.container {
    width: 100%;
    background-color: color('white');
}

.appName {
    margin: 0;
    padding: 0;
    line-height: 1;
}

.appVersion {
    font-size: 0.7rem;
    color: color('gray');
    margin: 0;
    padding: 0;
}

.innerContainer {
    @include containerWrapper;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.title {
    width: $navbarLogoWidth;

    a {
        display: block;
        text-decoration: none;
        border-right: 1px solid color('mercury');
    }

    span {
        color: color('primary');
        font-size: 22px;
    }

    span:nth-child(1) {
        @include fontWeight('bold');
        font-style: normal;
    }

    span:nth-child(2) {
        @include fontWeight('light');
        font-style: italic;
    }
}

.itemsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

.item {
    color: color('primary');
    padding: 0 $navbarItemHorizontalPadding;

    a {
        display: block;
        text-decoration: none;
        color: color('lightblack');
        @include fontWeight('bold');
        padding: $navbarItemVerticalPadding 0;
        text-transform: uppercase;
        font-size: 16px;
        border-bottom: 2px solid transparent;
        transition: 0.2s;
    }

    a.itemActive,
    a:hover {
        color: color('primary');
        border-bottom: 2px solid color('primary');
    }
}

.rightContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.userItem {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #bababa;
        margin: 0 0.8rem;
        min-width: 40px;
    }

    .userName {
        padding: 0 0.8rem;
        color: color('gray');
        font-size: 14px;
        border-left: 1px solid color('mercury');
        word-break: keep-all;
    }
}

.dropdownContainer {
    position: absolute;
    margin-top: 5px;
    background-color: color('white');
    border-radius: 4px;
    border: 1px solid color('athensGray');
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 800;

    .dropdownItem {
        font-size: 16px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        outline: 0;
        padding: 14px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: color('lightblack');

        &:hover {
            background-color: darken(color('white'), 0.8);
        }
    }

    .dropdownItemIcon {
        margin-right: 10px;
    }
}
