@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";
@import "../../../../../../styles/utils/responsive.scss";

.columnsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
    
    // flex-direction: row;
    // flex-wrap: wrap;

    .column {
        display: flex;
        flex-direction: column;
        flex: 1;

        .colHeader {
            border: 1px solid color('mercury');
            background-color: color('athensGray');
            text-align: center;
            font-size: 14px;
            @include fontWeight("bold");
            padding: 10px;
            white-space: nowrap;
        }

        .colContent {
            border: 1px solid color('mercury');
            background-color: white;
            text-align: center;
            flex: 1;
        }
    }
}

.subtitleLine{
    font-size: 14px;
    padding: 10px 0 10px 0; 
    text-align: right;    
}

.subtitleText{
    color: color('lightblack');
    vertical-align: middle;
}

.starsVerticalAlign{
    vertical-align: text-top;
}

.withoutElements{
    color: color('primary');
    background: color("lightGrayishBlue");
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    padding: 10px 10px 10px 10px;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
scrollbar-width: none;
    text-align: center;
}
