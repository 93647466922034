@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.paginationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.5rem;

    .paginationInfo {
        flex: 1;
        font-size: 0.95rem;
        color: color("gray");
    }
}

.counter {
    border-radius: 50%;
    background-color: color("primary");
    color: color("white");
    font-size: 0.65rem;
    display: inline-block;
    flex-direction: row;
    width: 15px;
    height: 15px;
    position: absolute;
    mix-blend-mode: darken;
    margin-left: 5px;
    margin-top: 1px;
}

.counterNumber {
    padding-top: 2px;
}

.noMarginPadding {
    margin: 0 !important;
    padding: 0 !important;
}

.divTablesContent {
    display: flex;
    flex-direction: row;
}

.divTablesLeft {
    z-index: 2;
    width: 50%;
}

.divTablesRight {
    width: 50%;
}

.buttonContainer {
    .buttonInnerContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 20px 0;

        .buttonLeft {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border: solid 1px color("primary");
            box-shadow: none;
        }

        .buttonRight {
            border: solid 1px color("primary");
            margin-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            box-shadow: none;
        }
    }
}

.btnFilters{
    border: 1px solid color("primary") !important;
    padding: 0.438rem 1.8rem !important;
    font-size: 0.95rem !important;
}

.bold {
    font-weight: bold !important;
}

.clickable {
    cursor: pointer;
}