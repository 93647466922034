@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.formContent {
    width: 100%;
}

.formLine {
    display: flex;
    flex-direction: row;
}

.formItem {
    width: 50%;
    padding: 1rem;
}

.bold {
    @include fontWeight("bold");
}

.floatLeft {
    float: left;
}

.buttonsFooter {
    text-align: right;
    padding: 1rem;
}

.tableHeader {
    width: 7.14%;
    background-color: color(linkWater);
    border: 1px solid color(mercury);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding: 0.2rem 0;

    &:first-child {
        border-top-left-radius: 0.5rem;
    }

    &:last-child {
        border-top-right-radius: 0.5rem;
    }
}

.tableHeaderAdd {
    width: 7.14%;
}

.tableRow {
    width: 7.14%;
    border: 1px solid color(mercury);
    text-align: center;
    font-size: 14px;
    color: color("gray");
    font-weight: bold;
    padding: 0.5rem 0;

    &.firstCol {
        background-color: color(catskillWhite);
        color: black;
    }

    &.tableRowDetails {
        display: flex;
        justify-content: space-between;
        padding: 0 0.5rem;
        align-items: center;
    }
}

.input {
    display: block;    
    padding: 0.8rem 1rem;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    font-size: 14px;
    width: 100%;
    outline: 0;
    border: 1px solid transparent;
    border-radius: 2px;
    font-family: 'Roboto', sans-serif;
    &:focus {
        border: 1px solid color('primary');
    }
    &::placeholder{
        font-family: 'Roboto', sans-serif;
    }
}

.inputDefault {
    background-color: color('white');
    border: 1px solid color('mercury');
}

.inputDisabled{
    background-color: color('whisper');
    opacity: 1;
}