@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.boxContent {
    padding: 0;
}

.boxBadge {
    margin-right: 10px;
    font-style: normal;
}

.bold {
    @include fontWeight("bold");
}

.align{
    align-self: center;
    padding-bottom: 10px;
}

.internalAllocationBox {
    margin: 3rem 0;
}