
@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.screenContainer{
    text-align: center;
}

.divTitle{
    margin-top: 20%;
}


.title{
    text-transform: uppercase;
}