@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.item {
    height: 100%;
    width: 100%;
    // flex: 1;
    // color: color('lightblack');
    // font-size: 14px;
    // padding: 4px;
    // cursor: pointer;
    // position: relative;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    user-select: none;
    // justify-content: flex-end;

    background: color('white');
    // background: linear-gradient(to right bottom, #fff calc(50% - 1px), #e9eaea, #ebf7ff calc(50% + 1px));
    position: relative;
    
    .diagonalTop,
    .diagonalBottom {
        position: absolute;
        width: 50%; 
        height: 50%;
        overflow: hidden;
scrollbar-width: none;
        color: color('black');
        @include fontWeight('bold');
        font-size: 0.8rem;

        &.diagonalIsSubRow {
            font-weight: normal;
            font-size: 0.7rem;
        }
    }

    .diagonalTop {
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 0.3rem;
        padding-left: 0.3rem;

        &.diagonalIsSubRow {
            padding-top: 0.2rem;
            padding-left: 0.2rem;
        }
    }

    .diagonalBottom {
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 0.3rem;
        padding-bottom: 0.3rem;

        &.diagonalIsSubRow {
            padding-right: 0.2rem;
            padding-bottom: 0.2rem;
        }
    }

}
