@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.boxContent {
    padding: 0;
}

.col {
    margin-bottom: 2rem;
}

.separator {
    background-color: color('mercury');
    height: 1px;
    width: 100%;
    margin: 0.5rem 0 2rem 0;
}
