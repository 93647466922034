@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    display: flex;
    border-top: 1px solid color("mercury");
    height: 100%;
}

.scrollPane {
    overflow-y: hidden;
    overflow-x: auto;
    
    &::-webkit-scrollbar {
        height: 0rem;
    }
}

.items{
    // overflow: hidden;
scrollbar-width: none;
    white-space: nowrap;
    height: 100%;
    width: 100%;
}

.item {
    width: 80px;
    display: inline-block;
    height: 100%;
    min-height: 48px;
    border-right: 1px solid color('mercury');
    position: relative;
    height: 100%;

    &.lastYearItem {
        border-right: 2px solid color('mercury');
    }
}
