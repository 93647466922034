@mixin containerWrapper {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    //padding-right: 15px; //se isto existir, quando a barra azul nos dashboards inicialmente estiver escondida no maximo do scroll a direita, ela aparece sobre o cinzento
    //padding-left: 15px;

    // @media (min-width: 640px) {
    //     max-width: 640px;
    // }

    // @media (min-width: 768px) {
    //     max-width: 768px;
    // }

    // @media (min-width: 1024px) {
    //     max-width: 1024px;
    // }

    // @media (min-width: 1280px) {
    //     max-width: 1280px;
    // }

    // @media (min-width: 1536px) {
    //     max-width: 1536px;
    // }
    @media (min-width:576px) {
        max-width: 540px
    }

    @media (min-width:768px) {
        max-width: 720px
    }

    @media (min-width:992px) {
        max-width: 960px
    }

    @media (min-width:1200px) {
        max-width: 1140px
    }

    @media (min-width: 1536px) {
        max-width: 1476px;
    }

    @media (min-width: 1680px) {
        max-width: 1620px;
    }
}

@mixin fontWeight($weight) {
    $weights: (
        thin: 100,
            extra-light: 200,
            ultra-light: 200,
            light: 300,
            normal: 400,
            book: 400,
            regular: 400,
            medium: 500,
            semi-bold: 600,
            demi-bold: 600,
            bold: 700,
            extra-bold: 800,
            ultra-bold: 900,
            heavy: 900,
            black: 900,
            ultra: 900,
            ultra-black: 900,
            extra-ultra: 900
    );

    $output: $weight;

    @if map-has-key($weights, $weight) {
        $output: map-get($weights, $weight);
    }

    font-weight: $output;
}

@mixin placeholderColor($color) {
    &.placeholder {
        color: $color
    }

    &:-moz-placeholder {
        color: $color
    }

    &::-webkit-input-placeholder {
        color: $color
    }

    &:-ms-input-placeholder {
        color: $color
    }
}

@mixin scrollbars() {
    &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
        background: color('primary');
    }

    &::-webkit-scrollbar-track {
        background: color('white');
    }

    //mozilla
    scrollbar-color: color('primary') color('white');
    scrollbar-width: thin;
}
