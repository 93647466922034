@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";


.multiselect {
    color: color(primary);
    white-space: nowrap;
    overflow: hidden;
scrollbar-width: none;
    text-overflow: ellipsis;
}