@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .toolbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        

        .title {
            flex: 1;
        }

        .toolbarButton {
            margin-left: 10px;
        }

        .toolbarButtonIcon {
            color: color("white");
        }
    }

    .toolbarItemsPerPage {
        margin-bottom: 1.5rem;
    }
}
