@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.content{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    opacity: 1;
    //height: fit-content;
    padding: 1.5rem 1.5rem 1rem;
    z-index: 8;
    overflow-y: scroll;
    @include scrollbars();
}

.overlayContainer {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0);
    z-index: 6;
}


