@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

$cellVerticalPadding: 6px;
$cellHorizontalPadding: 8px;

.tables {
    display: flex;
    flex-direction: row;
}

.table {
    flex: 1;
}

.flexEnd{
    justify-content: flex-end;
}

.rightTable {
    flex: 1;
    overflow: hidden;
    scrollbar-width: none;
    overflow-x: auto;
    @include scrollbars();
}

.columns {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.columnCell {
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    color: color("gray");
    @include fontWeight("bold");
    padding: $cellVerticalPadding $cellHorizontalPadding;
    cursor: pointer;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    // white-space: nowrap;
    // overflow: hidden;
    scrollbar-width: none;
    // text-overflow: ellipsis;
}

.columnCellAction {
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    color: color("gray");
    @include fontWeight("bold");
    padding: $cellVerticalPadding $cellHorizontalPadding;
}

.rows {
    display: flex;
    flex-direction: column;
    background-color: color("white");
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid color("mercury");
    border-top: 0;
    font-size: 14px;
    color: color("lightblack");
    height: 100%;

    &.emptyRow {
        border-top: 1px solid color("mercury");
        height: 36px;
    }
}

.rowFilter {
    align-items: center;
    border: 1px solid color("mercury");
    border-top: 0;
    font-size: 14px;
    color: color("lightblack");
    height: 100%;
}

.rowHover:hover {
    background-color: color("solitude");
}

.rowClickable {
    cursor: pointer;
}

.rowCell {
    color: color("red");
    font-size: 14px;
    //padding: $cellVerticalPadding $cellHorizontalPadding;
    align-items: center;
    height: 100%;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    display: flex;
    padding: $cellVerticalPadding $cellHorizontalPadding;
}

.noResultsText {
    text-align: center;
    color: color("gray");
    padding: 1rem;
    justify-content: center;
}

.iconOrder {
    float: right;
    color: color("primary");
}

.contentTd {
    flex: 1;
}

.iconExpand {
    float: right;
    color: color("black");
    margin-top: 2px;
}

.expandCell {
    min-width: 30px;
    padding: $cellVerticalPadding $cellHorizontalPadding;
}

.tooltip {
    white-space: nowrap;
    max-width: 40vw;
    overflow: hidden;
    scrollbar-width: none;
    text-overflow: ellipsis;
}
