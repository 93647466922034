@import "../../../../../../styles/utils/functions.scss";
@import "../../../../../../styles/utils/mixins.scss";


.subtitleLine{
    font-size: 14px;
    padding: 10px 0 10px 0; 
    text-align: right;    
}

.subtitleText{
    color: color('lightblack');
    vertical-align: middle;
}