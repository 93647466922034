@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.input {
    display: block;
    border: 0;
    padding: 0.8rem 1rem;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    font-size: 16px;
    width: 100%;
    outline: 0;
    border: 1px solid transparent;
    border-radius: 2px;
    font-family: 'Roboto', sans-serif;
    &:focus {
        border: 1px solid color('primary');
    }
    &::placeholder{
        font-family: 'Roboto', sans-serif;
    }
}

.inputWithIcon {
    display: block;
    border: 0;
    padding: 0.8rem 1rem;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    font-size: 16px;
    width: 50%;
    outline: 0;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;

    &:focus {
        border: 1px solid color('primary');
    }
}

.inputRangeWithIcon {
    display: block;
    border: 0;
    padding: 0.55rem !important;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    font-size: 16px;
    width: 50%;
    outline: 0;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;

    &:focus {
        border: 1px solid color('primary');
    }
}

.default {
    background-color: color('white');
    border: 1px solid color('mercury');
}

.login {
    background-color: color('catskillWhite');
}

.disabled{
    background-color: color('whisper');
    opacity: 1;
}

.maxLength{
    font-size: 12px;
    color: color('primary');
    display: block;
    margin-bottom: 4px;
}

.iconInput {
    color: #aaa;
    background-color: color('whisper');
    color: color('black');
    border-radius: 0 2px 2px 0;
    padding: 0.8rem 1rem;

}

.iconRangeInput{
    color: #aaa;
    background-color: color('whisper');
    color: color('black');
    border-radius: 0 2px 2px 0;
    padding: 0.6rem !important;
}

.formLine {
    display: flex;
    flex-direction: row;
}
  


.radioInput {
    border: 0px;
    width: 1.2em;
    height: 2em;
    padding-left: 35px;
    vertical-align: middle;
    cursor: pointer;
}

.radioInputLabel {
    padding-left: 20px;
}