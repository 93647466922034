@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.detailsContainer {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    
    .edit {
        display: none;
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translate(0, -50%);
    }

    &:hover {
        .edit {
            display: block;
        }
    }

    &:hover:focus-within {
        .edit {
            display: none;
        }
    }
}

.editContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .input {
        &:not(:focus) {
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            -moz-appearance: textfield;
        }
    }
}
