@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.header {
    background: color("white") 0% 0% no-repeat padding-box;
    border: 1px solid color("mercury");
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
scrollbar-width: none;
    padding: 1rem 1rem;
}

.buttonReturn {
    font-size: 16px;
    color: color("primary");
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    margin: 5px;
    float: left;
    cursor: pointer;
}

.labelTitle {
    @include fontWeight("bold");
    color: color("primary");
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    padding-left: 10px;
}

.buttonOptions {
    font-size: 16px;
    color: color("primary");
    text-align: right;
    letter-spacing: 0px;
    opacity: 1;
    margin: 5px;
    float: right;
    cursor: pointer;
}

.content {
    background: color("white") 0% 0% no-repeat padding-box;
    border: 1px solid color("mercury");
    // display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
scrollbar-width: none;
    padding: 1rem 1rem;
}