@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.formContent {
    width: 100%;
}

.formLine {
    flex-direction: row;
}

.formItem {
    width: 50%;
    padding: 1rem
}

.floatLeft {
    float: left;
}

.bold {
    @include fontWeight("bold");
}

.buttonsFooter {
    text-align: right;
    padding: 1rem
}