@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

$cellVerticalPadding: 6px;
$cellHorizontalPadding: 8px;

.columns {
    display: flex;
    flex-direction: row;
    align-items: center;
    &.emptyColumn {
        // border-top: 1px solid color('mercury');
        width: 36px;
    }
}

.columnCell {
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    color: color('gray');
    @include fontWeight('bold');
    padding: $cellVerticalPadding $cellHorizontalPadding;
}

.rows {
    display: flex;
    flex-direction: column;
    background-color: color('white');
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: color('lightblack');

    &.emptyRow {
        border-top: 1px solid color('mercury');
        height: 36px;
    }
}

.rowHover:hover {
    background-color: color('solitude');
}

.rowBorderTop{
    border-top: 1px solid color('mercury');
}

.rowBorderBottom{
    border-bottom: 1px solid color('mercury');
}

.rowCell {
    color: color('lightblack');
    font-size: 14px;
    padding: $cellVerticalPadding $cellHorizontalPadding;
    cursor: pointer;
}

.noResultsText {
    text-align: center;
    color: color('gray');
    padding: 1rem;
    justify-content: center;
}
