@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.paginationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.5rem;

    .paginationInfo {
        flex: 1;
        font-size: 14px;
        color: color('gray');
    }
}
.formContent {
    width: 100%;
}

.bold {
    @include fontWeight("bold");
}
.formLine {
    display: flex;
    flex-direction: row;
}

.formItem {
    align-items: left;
}
.floatLeft {
    float: left;
}

.buttonsFooter {
    text-align: right;
    padding: 1rem
}
