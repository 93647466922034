@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.text{
    text-align: center;
}


.screenContainer{
    text-align: center;
}

.divTitle{
    margin-top: 20%;
}


.title{
    text-transform: uppercase;
}