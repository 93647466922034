@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.badge {
    border: 0;
    color: color('white');
    outline: 0;
    display: inline-block;
    font-style: italic;
    text-align: center;
    width: fit-content;
    

    &.sizeNormal {
        padding: 3px 8px;
        font-size: 10px;
        border-radius: 25px;
    }

    &.sizeBig {
        padding: 6px 16px;
        font-size: 12px;
        border-radius: 28px;
    }
}

.primary {
    color: color('primary');
    background-color: lighten(color('primary'), 40%);
}

.primaryDark {
    color: color('white');
    background-color: color('primary');
}

.danger {
    color: color('danger');
    background-color: lighten(color('danger'), 40%);
}

.success {
    color: color('success');
    background-color: lighten(color('success'), 40%);
}
