@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.sidebar {
    
}

.panelFront{
    z-index: 1;
}

.sideBarContent{
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sidebarTitle {
    font-size: 16px;
    margin-bottom: 1.5rem;
}

.columnConfig {
    height: 40px;
    display: flex;
    flex-direction: row;
}


.columnConfigNotVisible {
    color: color("veryLightGrey");
}

.columnConfigEye {
    margin-right: 10px;
    cursor: pointer;
}

.columnConfigEyeVisible {
    color: color("primary");
}

.columnConfigCursor {
    color: color("gainsboro");
}

.columnConfigCursor {
    color: color("gainsboro");
}

.columnConfigContent {
    flex: 1;
    font-size: 14px;
}


.buttonsFooter {
    text-align: center;
    //bottom: 3rem;
    //position: absolute;
    width: 100%;
}

.dragContainer{
    overflow-y: scroll;
    //max-height: 83%;
    flex: 1;
    @include scrollbars();
}