@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.content {
    flex: 1;
}

.line {
    display: flex;
    flex-direction: row;
}

.item50 {
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.item {
    width: 100%;
}

.title {
    @include fontWeight("bold");
    font-size: 16px;
}

.tableLine {
    margin-top: 20px;
}

.bold {
    @include fontWeight("bold");
}

.totalLabel {
    font-size: 12px;
    text-transform: uppercase;
    color: color("gray");
    @include fontWeight("bold");
    text-align: left;
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.5rem;

    .paginationInfo {
        flex: 1;
        font-size: 0.95rem;
        color: color("gray");
    }
}

.iconFilter {
    margin-right: 5px;
}

.iconOrder {
    float: right;
    color: color("primary");
}

.header {
    background-color: color("white")!important;
    border-bottom: solid 1px#E8E8E8!important;
    text-transform: uppercase!important;
}

.headerName {
    font-size: 12px;
    @include fontWeight("bold");
}

.noData {
    height: 50px;
    width: 100%;
    text-align: center;
    background-color: white;
    padding: 1rem;
    color: color("gray");
}

.table{
    box-shadow: none;
}

.icon {
    color: color('primary');
}